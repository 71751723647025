body.color-scheme-0 {
  input[type="button" i] {
    background-color: #FFFFFF;
    color: #000000;
  }
  input[type='range'] {
    &::-webkit-slider-runnable-track {
      background-color: #FFFFFF;
      border-color: #F7A62F;
    }
    &::-webkit-slider-thumb {
      background-color: #FFFFFF;
      border-color: #F7A62F;
    }
    &:focus {
      &::-webkit-slider-thumb {
        background-color:  #FFFF12;
      }
    }
  }
  .color-scheme-text {
    color: #000000;
  }
  .color-scheme-bg {
    background-color: #FFFFFF;
  }
  .color-scheme-border {
    border-color: #F7A62F;
  }
  .color-scheme-border:focus {
    background-color: #FFFF12;
  }
  .color-scheme-border-alt {
    border-color: #FFFF12;
  }

}

body.color-scheme-1 {
  input[type="button" i] {
    background-color: #000000;
    color: #FFFFFF;
  }
    input[type='range'] {
    &::-webkit-slider-runnable-track {
      background-color: #000000;
      border-color: #000099;
    }
    &::-webkit-slider-thumb {
      background-color: #000000;
      border-color: #000099;
    }
    &:focus {
      &::-webkit-slider-thumb {
        background-color:  #139914;
      }
    }
  }
  .color-scheme-text {
    color: #ffffff;
  }
  .color-scheme-bg {
    background-color: #000000;
  }
  .color-scheme-border {
    border-color: #000099;
  }
  .color-scheme-border:focus {
    background-color: #139914;
  }
  .color-scheme-border-alt {
    border-color: #139914;
  }
}

body.color-scheme-2 {
  input[type="button" i] {
    background-color: #000099;
    color: #FFFF12;
  }
    input[type='range'] {
    &::-webkit-slider-runnable-track {
      background-color: #000099;
      border-color: #FF0317;
    }
    &::-webkit-slider-thumb {
      background-color: #000099;
      border-color: #FF0317;
    }
    &:focus {
      &::-webkit-slider-thumb {
        background-color:  #80110B;
      }
    }
  }
  .color-scheme-text {
    color: #FFFF12;
  }
  .color-scheme-bg {
    background-color: #FFFF12;
  }
  .color-scheme-border {
    border-color: #FF0317;
  }
  .color-scheme-border:focus {
    background-color: #80110B;
  }
  .color-scheme-border-alt {
    border-color: #80110B;
  }
}

// body.color-scheme-2,
// body.color-scheme-2 input[type="button" i] {
//   background-color: #000099;
//   color: #FFFF12;
// }
// body.color-scheme-2 .color-scheme-border {
//   border-color: #FF0317;
// }
// body.color-scheme-2 .color-scheme-border:focus {
//   background-color: #80110B;
// }
// body.color-scheme-2 .color-scheme-border-alt {
//   border-color: #80110B;
// }
