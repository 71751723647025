/* div {
  color: #444;
} */

.home-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-direction: column;
  margin-bottom: 300px;
  height: 100%;
}

.flex-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.settings-button {
  height: 50px;
  width: 50%;
  margin-bottom: 20px;
  background-color: white;
  border-color: #444;
  border-width: 1px;
}

.settings-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
