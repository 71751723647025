@import "../styles/inputrange";
@import "../styles/unity";
@import "../styles/color_scheme";
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root,
.center-container {
  width: 100%;
  height: 100%;
}

.container-width {
  max-width: 480px;
}

.mb-2 {
  margin-bottom: 1rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.color-boxes {
}
.color-box {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 2px solid #000;
  margin: 10px;
}

.page-container {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.button-small {
  width: 225px;
  height: 50px;
  background-color: white;
  border-color: #444;
  border-width: 3px;
  border-style: solid;
  font-size: 2rem;
  font-weight: bold;
}
.button-medium {
  margin-bottom: 25px;
  padding: 1rem 2rem;
  border-color: #444;
  border-width: 3px;
  border-style: solid;
  font-size: 1.5rem;
  font-weight: bold;
}
.button-large {
  margin-bottom: 15px;
  width: 480px;
  height: 120px;
  border-color: #444;
  border-width: 3px;
  border-style: solid;
  font-size: 2rem;
  font-weight: bold;
  &.done {
    text-decoration: line-through;
  }
  &.locked {
    text-decoration: line-through;
  }
}

.button-large:focus,
.button-medium:focus,
.button-small:focus {
  outline: none;
}
.progress-container {
  border-style: solid;
  border-width: 3px;
}

.text-button {
  border-width: 0px;
  color: #444;
  background-color: white;
  font-size: 18px;
}
.unity-container {
  background: rgba(0, 0, 0, 0.3);
  background-size: cover;
  border: 2px solid #000;
  border-radius: 5px;
  position: relative;
  aspect-ratio: 16 / 9;
  // background-color: green;
  margin: 0 auto;
  display: block;
  width: auto !important;
  height: auto !important;
  max-width: calc(100% - 30px);
  &.loading-player {
    // margin-top: 5rem;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: calc(100% - 4px);
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
  display: block;
  content: "";
}
.flex {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mb-0 {
  margin-bottom: 0;
}
.flex-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: calc(100vh - 150px);
  flex-direction: column;
}
.saving {
  width: 480px;
  text-align: center;
}
.container {
  width: 100%;
  width: calc(100% - 30px);
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.authentication-input {
  &:focus {
    outline: 2px solid red;
  }
}

// @media (min-width: 1200px) {
//   .flex-container {
//     min-height: 642px;
//   }
//   .container {
//     max-width: 1140px;
//   }
// }
